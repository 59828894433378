import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { Switch, Route, withRouter, Redirect } from "react-router-dom"
import VideoPlaylist from './pages/videoplaylist'
import Home from './pages/home'
import { getGeoInfo } from './utils/utilityFunctions'

ReactGA.initialize('UA-170119857-3')

function RouterComponent({ location }) {
	let geoInfo
	useEffect(() => {		
		geoInfo = getGeoInfo()
	}, [getGeoInfo])

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
	}, [location])

	return (		
		<Switch>
			{
				geoInfo ?
					<span>
						{geoInfo}
					</span>
					: null
			}
			<Route path="/home">
				<Home />
			</Route>

			<Route path="/:slug/:id/:settings">
				<VideoPlaylist />
			</Route>
			<Route path="/:slug/:id">
				<VideoPlaylist />
			</Route>
			<Route path="/:slug">
				<VideoPlaylist />
			</Route>

			<Redirect to="/home" />
			
		</Switch>
	)
}

export default withRouter(RouterComponent)
