import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import styled, { createGlobalStyle  } from 'styled-components'
import logo from '../images/logo.png'

function Home(props) {	
	
	document.title = "SOCHOOL MUSIC"

	const pageBackgroundColor = '#a6c6ff'

	const MainAppView = styled.div`max-width:640px; border: 4px solid #6495ED; overflow:auto; background-color: ${pageBackgroundColor}; margin-left: auto; margin-right: auto; font-family: Nunito; height: 99vh; border-radius: 4px;`
	const HeaderDiv = styled.div`overflow:auto; margin-bottom:3px; width: 99%;`
	const TitleSpan = styled.span`color: white; font-size:18px; font-weight: bold; vertical-align: top; word-wrap: break-word; width: 45%; float:left;padding-left: 10px; padding-top: 5px;`
	const LogoImg = styled.img`max-width: 45%; float:right; max-height: 48px; text-decorationa: none; `

	const DivPlaylistCover = styled.div`margin-top: 20px;`

	const PlayListLink = styled(Link)`font-family: Nunito; font-size: 18px; color: white; text-decoration: none;
										padding-top: 3px; padding-bottom: 3px; padding-left: 10px; padding-right: 10px;
										border-radius: 6px; border: solid 1px white; margin-bottom: 10px;
									`

	const SubjectHeader = styled.div`font-size: 20px;font-weight: bold; margin-top: 35px; color: yellow; margin-bottom: 10px;`

	return (
		<MainAppView>
			<HeaderDiv>
				<TitleSpan>SOCHOOL - EDUCATIONAL VIDEO PLAYLISTS</TitleSpan>
				<LogoImg src={logo} />
			</HeaderDiv>
			<div style={{width: '100%', margin: 'auto'}}>
				<div style={{width: '100%', margin: 'auto', textAlign: 'center'}}>
					<SubjectHeader>PLAYLISTS</SubjectHeader>
					<div><PlayListLink to="/nursery-ryhmes-1">Nursery Rhymes</PlayListLink></div>
					<div>&nbsp;</div>
				</div>
			</div>
		</MainAppView>
	)
}

export default Home
