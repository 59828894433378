import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import RouterComponent from './RouterComponent'
import * as serviceWorker from './serviceWorker'
import { createBrowserHistory } from 'history'
import { BrowserRouter as Router } from "react-router-dom"

let history = createBrowserHistory()

ReactDOM.render(
  	<React.StrictMode>
		<Router history={history}>
    		<RouterComponent />
		</Router>
  	</React.StrictMode>,
  	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
