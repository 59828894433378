import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import ReactPlayer from 'react-player'
import { WhatsappShareButton, WhatsappIcon } from 'react-share'
import styled, { createGlobalStyle  } from 'styled-components'
import { Link, useParams, useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import Switch from '@material-ui/core/Switch'
import logo from '../images/logo.png'
import onlyLogo from '../images/only-logo.png'
import { VideoPlaylists1 } from '../data/playlists'
import { PlaylistItem } from '../components/playlistSubitems'

let videoVolume = 0.4

const pageBackgroundColor = '#a6c6ff'

createGlobalStyle `body { background-color: ${pageBackgroundColor}; }`
const MainAppView = styled.div`max-width:640px; border: 4px solid #6495ED; overflow:auto; background-color: ${pageBackgroundColor}; margin-left: auto; margin-right: auto; font-family: Nunito; height: 99vh; border-radius: 4px;`
const HeaderDiv = styled.div`margin-bottom:3px; width: 99%; display: inline-block;`
const TitleSpan = styled.span`color: white; font-size:16px; font-weight: bold; vertical-align: top; word-wrap: break-word; width: 60%; float:left;`
const HomeLink = styled.div`float:left;`
const PlaylistSelect = styled(Select)`z-index: 10; color: black; float: left; width: 70%;`
const LogoImg = styled.img`max-width: 35%; float:right; max-height: 48px; text-decoration: none;`

const LogoInText = styled.img`float:left; height: 30px; text-decoration: none;`
const LinkBase = styled(Link)`font-family: Nunito; font-size: 18px; color: white; text-decoration: none;`

const VideoTitle = styled.div`font-size: 18px; font-weight: bold; color: #6495ED; background-color: white; border-radius: 4px; width: 94vw; max-width: 630px; margin-bottom: 3px;`
const VideoTitleArtist = styled.span`font-size: 10px;`
const PlayerDiv = styled.div`float: left; font-size: 14px; font-weight: bold; vertical-align: top; margin-left: auto; margin-right: auto; text-align:center; align: center; `
const VideoPlayer = styled(ReactPlayer)`max-height: 240px; border-radius: 6px;`
const OutControlsDiv = styled.div`color: #6495ED; background-color: white; border-radius: 4px; width: 94vw; max-width: 630px; height: 32px; padding-left:5px; padding-right: 5px; margin-top:3px;`

const PlayListDiv = styled.div`float: left; padding-left: 10px; font-size: 14px; margin-top: 10px; overflow: auto; width:97%; height: 44vh;
								::-webkit-scrollbar { width: 18px; }
								::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3); border-radius: 6px; }
								::-webkit-scrollbar-thumb { border-radius: 6px; -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.9); }`

function VideoPlaylist(props) {	
	let { slug, id, settings } = useParams()
	
	const history = useHistory()
	
	let defaultLoopOnSame = false
	let defaultContinueNext = true	
	if (settings === 'only') {
		defaultLoopOnSame = true
		defaultContinueNext = false
	}

	let videos
	let pageTitle = ''
	let defaultShuffle = false
	let selectOptions = []
	let selectedOption = []
	let selectedPlaylistCategory = ''
		
	for (let i = 0; i < VideoPlaylists1.length; i++) {
		if (VideoPlaylists1[i].slug === slug) {			
			videos = VideoPlaylists1[i].videos
			pageTitle = VideoPlaylists1[i].title
			defaultShuffle = VideoPlaylists1[i].shuffle
			selectedPlaylistCategory = VideoPlaylists1[i].playlistCategory
			selectedOption.push({
				value: VideoPlaylists1[i].slug,
				label: VideoPlaylists1[i].title,
			})
		}
	}

	if (!id) {
		id = 0
		history.push(`/${slug}/${Math.floor(Math.random() * videos.length)}`)
	}

	for (let i = 0; i < VideoPlaylists1.length; i++) {
		if (VideoPlaylists1[i].list === true && VideoPlaylists1[i].playlistCategory === selectedPlaylistCategory ) {
			selectOptions.push({
				value: VideoPlaylists1[i].slug,
				label: VideoPlaylists1[i].title,
			})
		}
	}

	const [activeVideoID, setActiveVideoID] = useState(id)
	const [loopOnSame, setLoopOnSame] = useState(defaultLoopOnSame)
	const [continueNext, setContinueNext] = useState(defaultContinueNext)
	const [shuffle, setShuffle] = useState(defaultShuffle)
	
	useEffect(() => {
		setActiveVideoID(id)		
	}, [id])

	if (videos[activeVideoID]) {	
		document.title = videos[activeVideoID].name + ' - SOCHOOL MUSIC'
	}

	const items = []
	let itemNumber = 0
	for (let i = 0; i < videos.length; i++) {
		if (settings !== 'only') {
			items.push(
				<PlaylistItem video={videos[i]} slug={slug} key={i} itemNumber={itemNumber} activeVideoID={activeVideoID} />
			)
		}
		itemNumber++
	}

	const getNextVideo = async () => {
		let nextVideoID = activeVideoID
		if (continueNext === true) {
			if(shuffle === true) {
				nextVideoID = Math.floor(Math.random() * videos.length)
			} else {
				nextVideoID = parseInt(activeVideoID) === (videos.length - 1) ? 0 : (parseInt(activeVideoID) + 1)
			}
			history.push(`/${slug}/${nextVideoID}`)
		}
	}

	return (
		<MainAppView>
			<ReactTooltip place="top" type="info" effect="float" multiline={true} border={true} id="settingsTooltip"
				getContent={ (dataTip) => dataTip ? <div><b style={{fontSize: 16}}>{dataTip.split('#')[0]}</b><div>{dataTip.split('#')[1]}</div></div>  : null }	
			/>
			<HeaderDiv>
				{
					settings !== 'only' ?
						<TitleSpan>
							<HomeLink>
								<LinkBase to="/">
									<LogoInText src={onlyLogo} />
								</LinkBase>
								>&nbsp;
							</HomeLink>
							<PlaylistSelect defaultValue={selectedOption} isSearchable="true" name="playlistSelect" options={selectOptions} onChange={ (newSelectedOption) => history.push(`/${newSelectedOption.value}`)} />
						</TitleSpan>
					: null
				}
				<LogoImg src={logo} />
			</HeaderDiv>
			<PlayerDiv>
				<VideoTitle>
					{videos[activeVideoID].name} <VideoTitleArtist>{videos[activeVideoID].artist ? '(' + videos[activeVideoID].artist + ')' : ''}</VideoTitleArtist>
					<WhatsappShareButton title={videos[activeVideoID].name + ' - ' + (videos[activeVideoID].artist ? '(' + videos[activeVideoID].artist + ')' : '') + ' - Educational Playlists' } style={{ float: 'right'}} url={String(window.location)}>
						<WhatsappIcon size={24} round={true} />
					</WhatsappShareButton>
				</VideoTitle>				
				<VideoPlayer url={videos[activeVideoID].url} controls={true} playing={true} loop={continueNext ? false: loopOnSame} width='100%' volume={videoVolume} onEnded={() => getNextVideo() } onVolumeChange={(e) => { videoVolume = e.target.volume } } />
				<OutControlsDiv>
					<span style={{ float: 'left'}} data-tip={'Repeat#Repeat the same video'} data-for="settingsTooltip">
						Repeat
						<Switch checked={loopOnSame} onChange={() => { if(!loopOnSame) { setContinueNext(false) } setLoopOnSame(!loopOnSame) } } />
					</span>
					<span style={{float: 'left', display: (settings !== 'only') ? 'block' : 'none' }} data-tip={'Continue#Continue to the next video when finish'} data-for="settingsTooltip">
						Continue
						<Switch	checked={continueNext} onChange={() => { if(!continueNext) { setLoopOnSame(false) } setContinueNext(!continueNext) } }/>
					</span>
					<span style={{float: 'right', display: (settings !== 'only') ? 'block' : 'none' }} data-tip={'Shuffle#Continue to a random video'} data-for="settingsTooltip">
						Shuffle
						<Switch	checked={shuffle} onChange={() => { setShuffle(!shuffle) } } style={{display: (settings !== 'only') ? 'block' : 'none' }}  />
					</span>
				</OutControlsDiv>
			</PlayerDiv>
			<PlayListDiv>
				{items}
			</PlayListDiv>
		</MainAppView>
	)
}

export default VideoPlaylist
