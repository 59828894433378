let vNurseryRhymes1 = [
	{ id: 0, name: 'Baby Shark', url: 'https://www.youtube.com/watch?v=XqZsoesa55w', artist: 'PinkFong' },	
	{ id: 1, name: 'FROZEN | Let It Go Sing-along', url: 'https://www.youtube.com/watch?v=L0MK7qz13bU', artist: '' },
	{ id: 2, name: 'FROZEN | In Summer Song', url: 'https://www.youtube.com/watch?v=UFatVn1hP3o', artist: '' },
	{ id: 3, name: '"Cant Stop The Feeling!" TROLLS', url: 'https://www.youtube.com/watch?v=oWgTqLCLE8k', artist: '' },
	{ id: 4, name: 'The Tortoise and the Hare', url: 'https://www.youtube.com/watch?v=WF8iaqRqI60', artist: '' },
	{ id: 5, name: 'Sesame Street: If You are Happy and You Know It', url: 'https://www.youtube.com/watch?v=5015skRvqs8', artist: '' },
	{ id: 6, name: 'Bath Song', url: 'https://www.youtube.com/watch?v=WRVsOCh907o', artist: '' },
	{ id: 7, name: 'True Colors', url: 'https://www.youtube.com/watch?v=3JIpIsgHqV0', artist: '' },
	{ id: 8, name: 'Twinkle Twinkle Little Star', url: 'https://www.youtube.com/watch?v=n38kGst16sI', artist: '' },
	{ id: 9, name: 'The Boo Boo Song', url: 'https://www.youtube.com/watch?v=3YltYCrPZos', artist: '' },
	{ id: 10, name: 'Deck the Halls', url: 'https://www.youtube.com/watch?v=Y2T_rIZ4Pho', artist: '' },
	{ id: 11, name: 'Jemaine Clement - Shiny', url: 'https://www.youtube.com/watch?v=93lrosBEW-Q', artist: '' },
	{ id: 12, name: 'Head Shoulders Knees & Toes', url: 'https://www.youtube.com/watch?v=QA48wTGbU7A', artist: '' },
	{ id: 13, name: 'Five Little Ducks', url: 'https://www.youtube.com/watch?v=pZw9veQ76fo', artist: '' },
	{ id: 14, name: 'Freeze', url: 'https://www.youtube.com/watch?v=2UcZWXvgMZE', artist: '' },
	{ id: 15, name: 'Head, Shoulders, Knees & Toes', url: 'https://www.youtube.com/watch?v=h4eueDYPTIg', artist: '' },
	{ id: 16, name: 'Un Poco Loco', url: 'https://www.youtube.com/watch?v=yZ7cBunq8xo', artist: '' },
	{ id: 17, name: 'Lighter', url: 'https://www.youtube.com/watch?v=1VRsNiX0b94', artist: '' },
	{ id: 18, name: 'Masha And The Bear', url: 'https://www.youtube.com/watch?v=Me8IqpAP87o', artist: '' },
	{ id: 19, name: 'FROZEN | Show Yourself', url: 'https://www.youtube.com/watch?v=nrZxwPwmgrw', artist: '' },
	// { id: 20, name: '', url: '', artist: '' },
	// { id: 21, name: '', url: '', artist: '' },
	// { id: 22, name: '', url: '', artist: '' },
	// { id: 23, name: '', url: '', artist: '' },
	// { id: 24, name: '', url: '', artist: '' },
	// { id: 25, name: '', url: '', artist: '' },
]

let VideoPlaylists1 = [
	{ name: 'NurseryRyhmes', title: 'NURSERY RHYMES 1', slug: 'nursery-ryhmes-1', videos: vNurseryRhymes1, shuffle: false, list: false, playlistCategory: 'nursery-rhymes' },
]

export {
	VideoPlaylists1
}
