import React, { useRef } from 'react'
import styled from 'styled-components'
import { Link } from "react-router-dom"

const PlayListItemLink = styled(Link)`font-family: Nunito; text-align: left; padding-top: 10px; border-bottom: solid 1px gray; text-decoration: none; vertical-align:top;`
const ItemText = styled.div`font-size: 14px; height:30px; padding-top:5px; padding-bottom:5px;padding-left: 10px; color: black; text-decoration: none; vertical-align:top; border-radius: 6px; margin-right:5px;`
const ItemTextSelected = styled.div`font-size: 16px; font-weight: bold; color: black; padding-left: 15px; border: solid 2px white; border-radius: 6px; padding-top:5px; padding-bottom:10px; margin-right:5px;`	

function PlaylistItem(props) {

	const mainItem = useRef(0);
	
	const video = props.video
	const slug = props.slug
	const itemNumber = props.itemNumber
	const activeVideoID = props.activeVideoID

	return (
		<PlayListItemLink to={`/${slug}/${itemNumber}` }>
			{ 
				(itemNumber === parseInt(activeVideoID)) ?
					<ItemTextSelected ref={mainItem} onMouseOver={() => { mainItem.current.style.webkitBoxShadow = 'inset 0 0 4px rgba(0,0,0,0.9)'; } } onMouseLeave={() => { mainItem.current.style.webkitBoxShadow = '' }}>
						{`${itemNumber + 1} - ${video.name} ${video.artist ? '(' + video.artist + ')' : ''} `}
					</ItemTextSelected> 
				:
					<ItemText ref={mainItem} onMouseOver={() => { mainItem.current.style.webkitBoxShadow = 'inset 0 0 4px rgba(0,0,0,0.9)'; } } onMouseLeave={() => { mainItem.current.style.webkitBoxShadow = '' }}>
						{`${itemNumber + 1} - ${video.name} ${video.artist ? '(' + video.artist + ')' : ''} `}
					</ItemText> 
			}
		</PlayListItemLink>
	)
}

export {
	PlaylistItem
}