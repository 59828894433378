import axios from 'axios'

const getGeoInfo = () => {
	axios.get('https://ipapi.co/json/').then((response) => {
		return response.data
	}).catch((error) => {
		console.log(error)
	})
}

export {
	getGeoInfo
}
